@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

.cs {
  &.cs-stat {
    position: absolute;

    &.cs-stat-total {
      input.clear-stat-total {
        @include clear-input;
        @include borderless-input;

        width: cscale(90px);
        height: cscale(90px);
        font-size: cscale(5em);
        font-weight: bold;
        text-align: center;
        color: $stat-color-total;
      }
    }

    &.cs-stat-base {
      height: cscale(120px);

      input.clear-stat-base,
      input.clear-stat-lifeform,
      input.clear-stat-loot {
        @include clear-input;
        width: cscale(38px);
        height: cscale(38px);
        border: none;
        font-size: cscale(2em);
        font-weight: bold;
        text-align: center;

        margin-top: cscale(2px);

        color: $stat-color-base;
        background-color: rgba(59, 59, 59, 0.144);
        border-radius: cscale(5px);

        @media print {
          background: none;
        }
      }
    }
  }
}
