$button-size: 25px;
$font-size: 0.8em;
$btn-disabled-opacity: 0.65;

button.playback.playback-button:disabled {
  cursor: default;
  opacity: $btn-disabled-opacity;
}

button.playback.playback-button {
  &.loop-active {
    color: var(--bs-success);
  }

  &.repeat-active {
    color: var(--bs-danger);
  }

  &.library-remove {
    text-align: center;
    :hover {
      color: red;
    }
  }

  &.playback-button-repeat {
    .secondsLabel {
      padding-left: 4px;
    }

    &.repeat-active {
      width: $button-size * 2.6;
    }
    &.repeat-inactive {
      width: $button-size * 1.5;
    }
  }

  &.playback-button-pause,
  &.playback-button-stop {
    color: var(--bs-danger);
  }

  width: $button-size;
  height: $button-size;
  // padding: 0.375rem 0.75rem;
  // margin: 0.25rem 0.125rem;

  color: var(--bs-dark);
  background-color: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-400);
  -webkit-tap-highlight-color: transparent;

  font-size: $font-size;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  text-transform: none;
  // font-weight: 400;
  // line-height: 1.5;

  -webkit-appearance: button;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  // border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;

  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
}
