@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

textarea.cs.cs-char-text {
  @include clear-input;

  position: absolute;
  resize: none;

  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;

  font-size: cscale(18px);
  color: $char-textarea-color;

  &.cs-char-text-notes-general {
    font-size: cscale(22px);
    line-height: cscale(26px);
  }

  &.cs-char-text-notes-healthbar {
    font-size: cscale(22px);
    line-height: cscale(26px);
  }

  &.cs-char-text-notes-bottom {
    font-size: cscale(24px);
    line-height: cscale(24px);
  }
}
