@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

.cs.capability-table {
  &.capability-table-container {
    margin-top: cscale(43px);
    margin-left: cscale(10px);
    margin-right: cscale(10px);

    display: flex;
    flex-direction: column;

    &.abilities {
      margin-left: cscale(40px);
    }
    &.powers {
      margin-left: cscale(40px);
      margin-top: cscale(40px);
    }
  }
}
