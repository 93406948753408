@import "../../assets/scss/colors.scss";

$dropzone-height: 30px;
$dropzone-width: 80%;

.sounds {
  &.sound-dropzone {
    &.sound-dropzone-container {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: stretch;

      min-height: $dropzone-height;

      // width: $dropzone-width;
      // min-width: $dropzone-width;

      .sound-dropzone-zone {
        flex-grow: 1;
        text-align: center;

        .dropzone {
          border: 1px dashed $bootstrap-secondary;

          &.active {
            border: 2px dashed rgba(253, 128, 128, 0.404);
            background-color: rgba(253, 128, 128, 0.404);
          }
          &.inactive {
            border: 2px dashed rgba(126, 126, 252, 0.301);
          }
        }
      }
    }
    &.sound-dropzone-progressbar {
      height: $dropzone-height;
      min-height: $dropzone-height;
    }
  }
}
