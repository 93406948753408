@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

input[type=checkbox].cs.cs-char-health.cs-char-health-heart {
  width: cscale(49px);
  height: cscale(49px);
  padding: 0;
  margin: 0;

  @include clear-input;

}

input[type=checkbox].cs.cs-char-health.cs-char-health-heart:before { 
  content:"";
  display:inline-block;
  width: cscale(49px);
  height: cscale(49px);
  background: $sheet-background-color;
  background-image: url("../../assets/img/heart.png");
  background-size: contain;
}

input[type=checkbox].cs.cs-char-health.cs-char-health-heart:checked:before { 
  background-image: url("../../assets/img/heart-active.png");
  background-size: contain;
}


