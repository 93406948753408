@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

$default-row-height: 43px;

.cs.capability-table.capability-row {
  // background-color:rgba(0, 0, 255, 0.336);
  height: cscale($default-row-height);
  margin-top: cscale(2px);

  display: flex;
  flex-direction: row;

  &.abilities {
    height: cscale($default-row-height + 32px);
  }
  &.powers {
    height: cscale($default-row-height + 30px);
  }
  &.augments {
    height: cscale($default-row-height + 2px);
  }

  &.abilities,
  &.powers {
    .capability-row-name {
      textarea {
        font-size: cscale(28px);
        line-height: cscale(34px);
      }
    }
  }

  &.augments {
    .capability-row-name {
      textarea {
        font-size: cscale(24px);
        line-height: cscale(20px);
      }
    }
  }

  &.capability-row-name {
    justify-content: flex-start;
    align-content: left;

    width: 100%;
    min-height: 100%;

    textarea {
      @include clear-input;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;

      resize: none;
      font-size: cscale(17px);
      color: $capability-item-color;

      // background-color: rgba(255, 0, 149, 0.336);
      // border: 1px solid blue;

      // critical to ensure no padding
      padding: 0px;
      box-sizing: inherit;
    }
  }
}
