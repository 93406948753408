.nav {
  &.downloading-active,
  &.uploading-active {
    position: absolute;
    bottom: 25px;
    right: 25px;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    // padding-right: 5px;
  }

  &.downloading-active {
    color: greenyellow;
  }
  &.uploading-active {
    color: greenyellow;
  }
}


@media print {
  .nav.navbar {
    display: none;
  }
}