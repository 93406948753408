$header-vertical-margin: 10px;

.sound_library {
  &.sound_library-loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      padding-right: 10px;
    }
  }

  &.sound_library-safari_warning {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-bottom: $header-vertical-margin;
  }

  &.sound_library-header {
    &.sound_library-header-container {
      display: flex;
      flex-direction: row;
      margin-top: $header-vertical-margin;
      margin-bottom: $header-vertical-margin;
    }

    &.sound_library-header-controls {
      display: flex;
      flex-direction: row;
    }

    &.sound_library-header-text {
      text-align: center;
      justify-content: center;
      flex-grow: 1;
    }

    &.sound_library-header-dropzone {
      flex: 1;
    }
  }

  &.sound_library-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;

    .sound_library-no_sounds {
      height: 300px;
      margin-top: $header-vertical-margin;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .sound_library-category-wrapper {
      // border: 2px solid orange;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      align-content: flex-start;

      .sound_library-category {
        &.sound_library-category-container {
          height: 100%;
          min-height: 100%;

          &.sound_library-category-container-editable {
            min-height: 220px;
          }

          // border: 1px solid green;

          // flex: 1;
          // flex-grow: 2;

          display: flex;
          flex-direction: column;

          margin-right: 5px;
          margin-bottom: 10px;

          padding: 5px;

          background-color: rgba(255, 255, 255, 0.158);

          &.drop-active {
            background-color: darkgreen;
            color: black;
            margin-bottom: 10px;
          }
          &.drop-enabled {
            background-color: #777;
            color: black;
          }
        }
        &.sound_library-category-header {
          font-size: 1.5em;
          font-weight: bold;
        }
        &.sound_library-category-footer {
          font-style: italic;
        }
        &.sound_library-category-draggable_sounds {
          // border: 1px solid red;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        &.sound_library-category-sounds {
          // flex-grow: 1;
          // border: 1px solid blue;

          margin-right: 5px;
          margin-bottom: 5px;

          // border: 1px solid red;
          // display: flex;
          // flex-direction: row;
          // flex-wrap: wrap;

          &.library-sound {
            //border: 1px solid red;
          }
        }
      }
    }
  }
}
