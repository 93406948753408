@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

.cs.noprint {
  @media print {
    display: none;
  }

  &.print-button {
    margin: 5px;
    font-family: $font-family;
    text-align: center;

    button {
      display: inline-block;
      display: inline-block;
      padding: 0.35em 1.2em;
      margin: 0 0.3em 0.3em 0;
      border-radius: 0.12em;
      box-sizing: border-box;
      text-decoration: none;
      font-family: $font-family;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      transition: all 0.2s;
      background: none;

      color: #ffffff;
      background-color: #000000;
      border: 0.1em solid #000000;
    }
    button:hover {
      color: #000000;
      background-color: #ffffff;
    }
  }
}

@media print {
  .cs {
    // force display of BG images when printing
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}

.cs {
  &.cs-icrpg-master-edition {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    width: cscale(1842px);
    height: cscale(2377px);

    background-image: url("../../assets/img/cs-icrpg-master-edition.png");
    background-color: $sheet-background-color;
    background-size: contain;

    font-family: $font-family;

    border: 1px solid var(#000000);

    z-index: 100;

    div {
      // background-color: rgba(59, 59, 59, 0.144);
      // border: 1px solid red;
    }
  }

  &.cs-header {
    /* header */

    position: absolute;
    left: 0px;
    top: 0px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;

    input {
      &.character_name {
        width: cscale(350px);
        color: $char-text-color;
      }
      &.world {
        width: cscale(330px);
        color: $char-text-color;
      }
      &.lifeform {
        width: cscale(340px);
        color: $char-text-color;
      }
      &.character_type {
        width: cscale(250px);
        color: $char-text-color;
      }
      &.story {
        width: cscale(1560px);
        color: $char-text-color;
      }
    }

    &.cs-header-character_name {
      /* name */

      position: absolute;
      left: cscale(156px);
      top: cscale(95px);
    }
    &.cs-header-world {
      /* world */

      position: absolute;
      left: cscale(606px);
      top: cscale(95px);
    }
    &.cs-header-lifeform {
      /* lifeform */

      position: absolute;
      left: cscale(1056px);
      top: cscale(95px);
    }
    &.cs-header-character_type {
      /* type */

      position: absolute;
      left: cscale(1478px);
      top: cscale(95px);
    }
    &.cs-header-story {
      /* story */

      position: absolute;
      left: cscale(170px);
      top: cscale(177px);
    }
  }

  &.cs-core {
    /* core */

    position: absolute;

    left: 0px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;

    &.cs-core-stats-left {
      /* stats-left */

      /* Auto Layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      left: cscale(294px);
      top: cscale(264px);

      .cs.cs-stat.cs-stat-base {
        position: absolute;
        left: cscale(186px);
        top: cscale(2px);
      }
    }

    &.cs-core-stats-left-str {
      /* str */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(10px);
    }
    &.cs-core-stats-left-dex {
      /* dex */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(167px);
    }
    &.cs-core-stats-left-con {
      /* con */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(322px);
    }
    &.cs-core-stats-left-int {
      /* int */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(474px);
    }
    &.cs-core-stats-left-wis {
      /* wis */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(629px);
    }
    &.cs-core-stats-left-cha {
      /* cha */

      /* Auto Layout */

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      top: cscale(784px);
    }

    &.cs-core-stats-middle {
      /* stats-left */

      /* Auto Layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      position: absolute;
      left: cscale(898px);
      top: cscale(264px);

      .cs.cs-stat.cs-stat-base {
        position: absolute;
        left: cscale(194px);
        top: cscale(-14px);
      }

      .cs-core-stats-middle-basic {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        top: cscale(18px);
      }
      .cs-core-stats-middle-weapons_tools {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        top: cscale(203px);
      }
      .cs-core-stats-middle-guns {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        top: cscale(396px);
      }
      .cs-core-stats-middle-energy_magic {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        top: cscale(590px);
      }
      .cs-core-stats-middle-ultimate {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        top: cscale(775px);

        .cs.cs-stat.cs-stat-base {
          top: cscale(-2px);
        }
      }
    }

    &.cs-core-stats-right {
      /* stats-right */

      /* Auto Layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding: 0px;

      position: absolute;
      left: cscale(1179px);
      top: cscale(264px);

      .cs-core-stats-right-notes_general {
        width: cscale(505px);
        height: cscale(511px);
        top: cscale(12px);
        left: cscale(12px);
      }

      .cs-core-stats-right-healthbar {
        position: absolute;
        width: cscale(514px);
        height: cscale(131px);
        left: cscale(12px);
        top: cscale(551px);
      }
      .cs-core-stats-right-notes_healthbar {
        position: absolute;
        width: cscale(508px);
        height: cscale(75px);
        left: cscale(12px);
        top: cscale(603px);
      }

      .cs-core-stats-right-defense {
        position: absolute;
        left: cscale(50px);
        top: cscale(801px);
      }

      .cs-core-stats-right-herocoins {
        position: absolute;
        left: cscale(232px);
        top: cscale(801px);
      }

      .cs-core-stats-right-dying {
        position: absolute;
        left: cscale(410px);
        top: cscale(781px);
      }
    }
  }

  &.cs-bottom {
    /* bottom */

    position: absolute;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    top: cscale(1205px);

    &.cs-bottom-loot {
      /* loot */

      position: absolute;
      width: cscale(825px);
      height: cscale(977px);
      left: cscale(85px);
      top: cscale(12px);

      display: flex;
      flex-direction: column;
    }
    &.cs-bottom-abilities {
      /* abilities */

      position: absolute;
      width: cscale(820px);
      height: cscale(436px);
      left: cscale(911px);
      top: cscale(12px);

      display: flex;
      flex-direction: column;
    }
    &.cs-bottom-powers {
      /* powers */

      position: absolute;
      width: cscale(820px);
      height: cscale(276px);
      left: cscale(911px);
      top: cscale(449px);

      display: flex;
      flex-direction: column;
    }
    &.cs-bottom-augments {
      /* augments */

      position: absolute;
      width: cscale(820px);
      height: cscale(263px);
      left: cscale(911px);
      top: cscale(726px);

      display: flex;
      flex-direction: column;
    }

    &.cs-bottom-notes_bottom {
      position: absolute;
      width: cscale(1235px);
      height: cscale(93px);
      left: cscale(481px);
      top: cscale(989px);
    }
  }
}
