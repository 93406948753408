.sound_library {
  &.sound_library-create {
    &.container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      max-width: 500px;
    }
    &.form {
      flex: 1;
      margin-top: 20px;
    }
  }
}
