@import "../../assets/scss/colors.scss";

.sound {
  &.sound-container {
    width: 150px;
    min-width: 150px;
    height: 126px;
    min-height: 126px;

    border: 1px solid $bootstrap-secondary;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.sound-container-edit {
      border: 2px dashed $bootstrap-warning;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    &.sound-container-playback {
    }

    position: relative;
  }

  &.sound-error {
    text-align: center;
    &.sound-error-icon {
      color: $bootstrap-danger;
    }
    &.sound-error-message {
      padding-left: 2px;
    }
  }

  &.sound-loading {
    text-align: center;
  }

  &.sound-media {
    &.library-remove {
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 5px;
    }

    &.sound-media-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      // border: 1px solid red;

      height: 70px;
      min-height: 70px;
    }

    &.sound-media-controls {
      text-align: center;
      // border: 1px solid red;
      margin-bottom: 5px;

      .loopActive {
        color: $bootstrap-success;
      }
      .repeaterActive {
        color: $bootstrap-warning;
      }

      .playback {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
          margin-right: 4px;
        }
        .seconds {
          padding-left: 4px;
        }
      }
    }
  }
}
