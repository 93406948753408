@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

.cs.cs-loot.cs-loot-checkbox {

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    display: none;
  }
  
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: cscale(49px);
    height: cscale(49px);        
    background-size: contain;
    top: cscale(24px);
  }

  [type="checkbox"]:not(:checked) + label {
    background-clip: none;
  }
  [type="checkbox"]:checked + label {
    background-image: url("../../assets/img/loot-checkbox-active.png");
  }
  
}
