@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

input.cs.cs-stat.stat-basic {
  @include clear-input;

  width: cscale(90px);
  height: cscale(90px);
  font-size: cscale(5em);
  font-weight: bold;
  text-align: center;
  color: $stat-color-basic;
}