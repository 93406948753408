@use "sass:meta";
@import "./variables.scss";

/* -------------------------------------------------------- */
/* variable "--global-scale" is used by mixins to calculate */
/* the scale of every unit on the character sheet           */
/*                                                          */
/* "--global-scale" is updated by the react app             */
/* -------------------------------------------------------- */

:root {
  --global-scale: 1.0
}

$global-scale: var(--global-scale); 

@function cscale($x) {  
  @return calc(#{$global-scale} * #{$x});
}

/* ------------------------------------------------- */

@mixin clear-input {  
  border-width: 0px;
  border: none;
  // background-color: rgba(59, 59, 59, 0.144);
  background: none;
  font-family: $font-family;
}

@mixin borderless-input {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;  
}

