@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

.cs.loot-table {
  &.loot-table-container {
    margin-top: cscale(43px);
    margin-left: cscale(20px);
    margin-right: cscale(10px);

    display: flex;
    flex-direction: column;
  }
}
