$slider-height: 8.4px;
$slider-margin: 18px;

$thumb-color: #0d6efd;
$track-color: #448dfa;

// http://danielstern.ca/range.css/?ref=css-tricks#/
@mixin volume-slider {
  input[type="range"] {
    width: 100%;
    margin: 5px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    background: $track-color;
    border: 0;
    border-radius: 10px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  input[type="range"]::-webkit-slider-thumb {
    margin-top: -5px;
    width: 18px;
    height: 18px;
    background: $thumb-color;
    border: 2.5px solid $thumb-color;
    border-radius: 12px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }
  input[type="range"]::-moz-range-track {
    background: $track-color;
    border: 0;
    border-radius: 10px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  input[type="range"]::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: $thumb-color;
    border: 2.5px solid $thumb-color;
    border-radius: 12px;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 7.6px 0;
    color: transparent;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  input[type="range"]::-ms-fill-lower {
    background: #d7d7d7;
    border: 0;
    border-radius: 20px;
  }
  input[type="range"]::-ms-fill-upper {
    background: $track-color;
    border: 0;
    border-radius: 20px;
  }
  input[type="range"]::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #0d6efd;
    border: 2.5px solid #0d6efd;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: $track-color;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ffffff;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type="range"] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
}
