@import "../../assets/scss/library-mixins.scss";

.playback {
  &.playback-control {
    &.playback-control-volume {
      @include volume-slider();
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5px;
      margin-top: -5px;
    }
  }
}
