@import "../../assets/scss/cs-mixins.scss";
@import "../../assets/scss/variables.scss";

$row-height: 43px;

.cs.loot-table.loot-row {
  // background-color:rgba(0, 0, 255, 0.336);
  height: cscale($row-height);
  margin-top: cscale(2px);

  // margin-top: cscale(2px);
  // margin-bottom: cscale(2px);

  display: flex;
  flex-direction: row;

  &.loot-row-name {
    justify-content: flex-start;
    align-content: left;
    width: cscale(639px);    
    min-height: 100%;

    textarea {
      @include clear-input;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;

      resize: none;
      color: $loot-item-color;

      font-size: cscale(22px);
      line-height: cscale(20px);
      
      // critical to ensure no padding
      padding: 0px;
      box-sizing:inherit;
    }
  }
  &.loot-row-equipped,
  &.loot-row-carried {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    width: cscale(80px);
  }
}
